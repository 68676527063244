/*------------------------------------*\
  #COMPAT -- hero
\*------------------------------------*/

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-hero__themecolor {
            &::after {
                background-color: map-get($themeColors, "primary"); // {1}
            }

            h1, h3 {
                color: $color-white;  // {2}
            }

            .c-hero__contentblock,
            .c-hero__contentblock a {
                color: map-get($themeColors, "primary");
            }

            a.c-breadcrumbs__page {
                color: $color-white;
            
                @include hocus() {
                    color: map-get($themeColors, "primary40"); // {3}
                }
            }
        }
    }
}
