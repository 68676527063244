/*------------------------------------*\
  #COMPAT -- Bacground-colors
\*------------------------------------*/

.o-background--primary {
    background-color: $color-brand-primary;

    a {
        color: $color-white;

        @include pocus() {
            color: $color-grey-light;
        }
    }
}

.o-background--primary75 {
    background-color: $color-brand-primary-75;
}

.o-background--primary40 {
    background-color: $color-brand-primary-40;
}

.o-background--primary10 {
    background-color: $color-brand-primary-10;
}

.o-background--primary02 {
    background-color: $color-brand-primary-02;
}

.o-background--secondary {
    background-color: $color-brand-secondary;
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .o-background--primary {
            color: #{map-get($themeColors, "primary")};
        }
        .o-background--primary75 {
            color: #{map-get($themeColors, "primary75")};
        }
        .o-background--primary40 {
            color: #{map-get($themeColors, "primary40")};
        }
        .o-background--primary10 {
            color: #{map-get($themeColors, "primary10")};
        }
        .o-background--primary02 {
            color: #{map-get($themeColors, "primary02")};
        }
    }
}

.o-background--positive {
    background-color: $color-positive;
}

.o-background--neutral {
    background-color: $color-neutral;
}

.o-background--warning {
    background-color: $color-warning;
}

.o-background--error {
    background-color: $color-error;
}
