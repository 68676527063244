/*------------------------------------*\
  #COMPAT -- Flags
\*------------------------------------*/

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-flag__body {
            .name {
                color: #{map-get($themeColors, "primary")}; // {1}
            }
        }
    }
}
