/*------------------------------------*\
  #COMPAT -- Menu
\*------------------------------------*/

.c-menu,
.c-menu__mobile {
    a {
        color: $color-white; // {1}
    
        @include hocus() {
            color: $color-grey-xlight; // {2}
        }
    }

    background-color: $color-brand-primary; // {3}
    color: $color-white; // {4}
}

.c-menu__item {
    @include mq($from: medium) {
        &--toggl {
            color: $color-brand-primary; // {5}
    
            a {
                color: $color-brand-primary; // {6}
    
                @include hocus() {
                    color: $color-brand-primary-shadow; // {7}
                }
            }
        }
    }
}

.c-submenu {
    @include mq($from: medium) {
        a {
            color: $color-black; // {8}
    
            @include hocus() {
                color: $color-brand-primary; // {9}
            }
        }
    }
}
