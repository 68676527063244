/*------------------------------------*\
  #COMPAT -- Links
\*------------------------------------*/

a {
    color: $color-links;

    @include pocus() {
        color:  $color-links-hover;
    }
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        a {
            color: map-get($themeColors, "primary");

            @include pocus() {
                color: darken(map-get($themeColors, "primary"), 8%);
            }
        }
    }
}
