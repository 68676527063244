/*------------------------------------*\
  #COMPAT -- Readmore
\*------------------------------------*/

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-readmore__heading i {
            color: #{map-get($themeColors, "primary")}; // {1}
        }
    }
}

.c-readmore {
    @include mq($from: tiny) {
        flex-basis: 48%;
    }

    @include mq($from: medium) {
        flex-basis: 31%;
    }

    @include mq($from: large) {
        flex-basis: 23%;
    }
}
