/*------------------------------------*\
  #COMPAT -- Article
\*------------------------------------*/

.c-article {
    strong {
        color: $color-brand-primary;
    }
}

.c-article__mainimage {
    max-width: 90vw;
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-article strong {
            color: #{map-get($themeColors, "primary")};
        }
    }
}
