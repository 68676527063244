/*------------------------------------*\
  #COMPAT -- Breadcrumbs
\*------------------------------------*/

.c-breadcrumbs {
    i {
        color: $color-brand-primary;
    }

    a {
        color: $color-grey-dark;

        @include pocus() {
            color: $color-black;
        }
    }
}

@each $themeName, $themeColors in $theme-colors {
    .t-#{$themeName} {
        .c-breadcrumbs i {
            color: #{map-get($themeColors, "primary")};
        }
    }
}
